import { useGetPromotedChallengesQuery } from "services/graphql";

export function useGetChallenges() {
  const { data, loading } = useGetPromotedChallengesQuery({
    fetchPolicy: "cache-first",
  });

  return {
    challenges: data?.challengesPromoted,
    loading,
  };
}
