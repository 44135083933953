import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Slider } from "app/components/Slider";
import { ChallengeProgressCard } from "app/components/ChallengeProgressCard";
import { ChallengeProgressCardSkeleton } from "app/components/ChallengeProgressCard/ChallengeProgressCardSkeleton";
import { ChallengeCardFragment } from "services/graphql";
import { ChallengeModal } from "app/components/ChallengeModal";
import { Routes } from "constants/routes";
import { Section } from "../Section";
import { useGetChallenges } from "./hooks";

export function PromotedChallenges() {
  const { challenges, loading } = useGetChallenges();
  const [selectedChallengeId, setSelectedChallengeId] = useState<string | null>(
    null
  );

  const loadingStateComponent = () => {
    return (
      <Slider
        data={[{}, {}, {}, {}, {}]}
        slideComponent={ChallengeProgressCardSkeleton}
        arrowTopPosition="35%"
        slidesSpacing={24}
        slidesWidth={400}
      />
    );
  };

  if (!loading && !challenges?.length) {
    return null;
  }

  return (
    <>
      <Section
        title="Challenges"
        showLoadingState={loading}
        showTitleLoadingState
        loadingStateComponent={loadingStateComponent()}
        headerLink={{
          route: Routes.challenges,
          text: "See All",
        }}
      >
        <Slider
          data={challenges}
          slideComponent={({
            data: challenge,
          }: {
            data: ChallengeCardFragment;
          }) => (
            <ChallengeProgressCard
              challenge={challenge}
              onClick={() => setSelectedChallengeId(challenge.id)}
            />
          )}
          selectedFrom="Promoted Challenges Carousel"
          arrowTopPosition="35%"
          slidesSpacing={24}
          slidesWidth={400}
        />
      </Section>

      {selectedChallengeId && (
        <ChallengeModal
          challengeId={selectedChallengeId}
          isOpen={!!selectedChallengeId}
          onClose={() => setSelectedChallengeId(null)}
        />
      )}
    </>
  );
}
